<template>
  <div class="view-mask view-mask-flex" v-if="showLoading">
    <div class="view-loading">
      <img src="https://cdn.kuaidi100.com/images/smart/order/loading.gif" class="view-loading-img">
      <p class="view-loading-text">{{text}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "loading",
  props: {
    show: Boolean,
    content: String
  },
  data() {
    return {
      text: this.content,
      showLoading: this.show
    }
  },
  watch: {
    show: function(val) {
      this.showLoading = val
    },
    content: function(val) {
      this.text = val
    }
  }
}
</script>