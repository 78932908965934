<!--支持内容和底部自定义-->
<template>
  <transition name="fade" @enter="enter" @after-leave="onHide">
    <div class="view-mask view-mask-flex" v-show="showOuter" style="transition-delay: .1s">
      <transition name="scale" @before-leave="onLeave">
        <div class="view-confirm" v-show="showInner">
          <div class="view-confirm-title" v-if="title">{{title}}</div>
          <slot :hide="hide">
            <div class="view-confirm-content" v-html="content"></div>
          </slot>
          <slot name="footer" v-if="!hideFooter">
            <div class="view-confirm-footer">
              <button class="view-confirm-btn cancel" @click="onCancel" v-if="type !== 'alert'">{{cancelText}}</button>
              <button class="view-confirm-btn confirm" @click="onConfirm">{{confirmText}}</button> 
            </div>
          </slot>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
export default {
  name: "confirm",
  model: {
    prop: 'show',
    event: 'hide'
  },
  props: {
    content:  {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "confirm",
      validator: function(value) {
        return ['confirm', 'alert'].indexOf(value) !== -1
      }
    },
    title: {
      type: String,
      default: ""
    },
    show: Boolean,
    hideFooter: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: "取消"
    },
    confirmText: {
      type: String,
      default: "确定"
    },
    cancel: Function,
    confirm: Function,
    confirmHide: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showInner: false,
      showOuter: false
    }
  },
  methods: {
    enter() {
      this.showInner = true
    },
    onLeave() {
      this.showOuter = false
    },
    onCancel() {
      this.$emit("cancel")
      this.hide()
    },
    onConfirm() {
      this.$emit("confirm", this)
      this.confirmHide && this.hide()
    },
    onHide() {
      this.$emit("hide", false)
    },
    hide() {
      this.showInner = false
    }
  },
  watch: {
    show: function(val) {
      this.showOuter = val
    },
  },
  created() {
    this.showOuter = this.show
  },
  mounted() {
    console.log(this.$props);
  }
}
</script>