<template>
  <div class="danmu-container" :style="{background:background}">
    <div class="danmu-box" v-if="showItem">
      <div class="box-user">
        <img class="box-avator" v-if="danmuItem.url" :src="danmuItem.url" alt />
        <span class="box-name" v-if="danmuItem.num">{{danmuItem.num}}</span>
        <span class="box-txt" v-if="danmuItem.text">{{danmuItem.text}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { danmuData } from "../../data/danmu618.js";
export default {
  name: "danmu",
  props: {
    background: {
      type: String,
      default: "url('https://cdn.kuaidi100.com/images/m/activity/618/danmu-back.png') center no-repeat"
    }
  },
  data() {
    return {
      showItem: false,
      danmuItem: ""
    };
  },
  created() {
    this.newList = danmuData.sort(() => {
      return Math.random() > 0.5 ? -1 : 1;
    });
    this.startScrool();
  },
  methods: {
    startScrool() {
      let curIndex = 0;
      let len = this.newList.length;
      this.danmuItem = this.newList[0];
      this.showItem = true;
      this.timer = setInterval(() => {
        this.danmuItem = this.newList[++curIndex % len];
      }, 2500);
    }
  }
};
</script>

<style scoped>
  .danmu-container {
    width: 16rem;
    height: 1.3rem;
    border-radius: 0.8rem;
    line-height: 1.3rem;
    background-color: #003cb7;
    opacity: 0.5;
    }
  .danmu-box {
    left: 0.5rem;
    display: flex;
    justify-content: center;
    animation: scrollInOut 2.5s linear infinite;
    -webkit-animation: scrollInOut 2.5s linear infinite;
  }
  .box-user {
    height: 100%;
  }
  .box-avator {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    vertical-align: middle;
  }
  .box-name,
  .box-txt {
    margin: auto 0.3rem;
    font-size: 0.78rem;
    color: #fff;
    font-weight: bold;
  }
  @keyframes scrollInOut {
    from {
      transform: translate(0, 0.3rem);
      opacity: 0;
    }
    30% {
      transform: translate(0, 0);
      opacity: 1;
    }
    50% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(0, -0.6rem);
      opacity: 0;
    }
  }

  @-webkit-keyframes scrollInOut {
    from {
      transform: (0, 0.3rem);
      opacity: 0;
    }
    30% {
      transform: translate(0, 0);
      opacity: 1;
    }
    50% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(0, -0.6rem);
      opacity: 0;
    }
  }
</style>