<template>
  <div class="global-transition-container">
    <transition :name="transitionName">
      <keep-alive :include="$store.state.keepAliveRoutes">
        <router-view class="global-transition-wrapper"></router-view>
      </keep-alive>
    </transition>
  </div>
</template>
<script>
import util from "lib/util"
let sessionHistory = util.getSession("$localHistory") || []
export default {
  name: "App",
  data: function () {
    return {
      transitionName: 'slide-left',
      historyStack: this.$route.meta.isRoot ? [] : sessionHistory,
      keepAliveRoutes: []
    }
  },
  watch: {
    historyStack: function() {
      util.setSession("$localHistory", this.historyStack)
    }
  },
  created () {
    // this.historyStack = []
    this.initRoutes(this.$router.options.routes)
    this.$store.commit("setKeepAliveRoutes", this.keepAliveRoutes)
    this.$router.beforeEach((to, from, next) => {
      this.setTransition("fade")
      /* if(this.$store.state.switchPage) {
        this.setTransition("fade")
        this.historyStack = []
      } else {
        let historyLen = this.historyStack.length
        if(this.$store.state.replacePage) {//执行了页面替换
          this.popStack()
          this.$store.commit("setReplacePage", false)
        }
        this.$store.commit("setReplacePage", false)
        if (historyLen === 0) {
          this.setTransition('slide-left')
          this.pushStack(from) // 首次加载后离开页面，增加历史
          this.pushStack(to) // 新增即将进入的历史栈
        } else if(historyLen === 1) {
          this.setTransition('slide-left')
          this.pushStack(to)
        } else {
          let lastRoute = this.historyStack[historyLen - 2]
          if (lastRoute === to.name) {
            this.popStack()
            this.setTransition('slide-right')
          } else {
            this.pushStack(to)
            this.setTransition('slide-left')
          }
        }
      } */
      next()
    })
  },
  methods: {
    pushStack (route) {
      this.historyStack.push(route.name)
    },
    popStack () {
      let pop = this.historyStack.pop()
      if(this.$store.state.keepAliveRoutes.indexOf(pop)) {
        this.$store.dispatch("removeKeepAliveRoutes", pop)
      }
    },
    setTransition (name) {
      this.transitionName = name
    },
    initRoutes(routes) { 
      let route
      for(let i = 0; i < routes.length; i++) {
        route = routes[i]
        if(route.meta && route.meta.keepAlive) {
          this.keepAliveRoutes.push(route.name)
        }
        route.children && route.children.length && this.initRoutes(route.children)
      }
    }
  }
}
</script>

<style lang="scss">
</style>

<style>
#udesk_container {
  display: none;
}
html,body{width: 100%;height: 100%;background: #efeff4;}
*{-webkit-tap-highlight-color: transparent;}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body,ul,ol,li,dl,dd,p,h1,h2,h3,h4,h5,h6,form,fieldset,table {
  margin: 0;
  padding: 0
}
html, body, input, textarea, select, button {
  text-align: left;
  font: 100% "Microsoft YaHei",Verdana,arial,sans-serif;
}
@media screen and (max-width: 320px){
  html {
    font-size: 14px;
  }
}
input, textarea, select, input[disabled], input:disabled {
  appearance: none;
  outline: none;
  background: transparent;
 
}
input[disabled], input:disabled {
  color: #333;
  opacity: 1;
  -webkit-text-fill-color: #333;
}
input:disabled::-webkit-input-placeholder, ::-webkit-input-placeholder {
  color: red;
  -webkit-text-fill-color: #BEBEBE;
}
dl, ul {
  list-style: none;
}
a, button, img, input, label, textarea, button {
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  outline: 0;
  border: none;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: #3278e6;
}
strong {
  color: #ff7f02;
  font-weight: 400;
}
.full-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.full-flex-content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.global-danger {
  color: red !important;
}
.global-warn {
  color: #ff7f02 !important;
}
.global-btn {
  display: block;
  margin: 10px;
  background: #ff7f02;
  border-radius: 3px;
  text-align: center;
  line-height: 48px;
  font-size: 1.125rem;
  color: #fff;
}

::-webkit-input-placeholder {
  color: red !important;
}
input[type=radio],input[type=checkbox] {
  -webkit-appearance:none;
  outline:none;
  border: none;
  background: transparent;
}

/* ---------页面切换动画 -------------*/
.global-transition-container{/* 容器 */
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  backface-visibility: hidden;
  perspective: 1000;
}
.global-transition-wrapper {/* 内容 */
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #efeff4;
}
.fade-enter-active{
  animation: fade 400ms forwards;
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* 路由前进，上一页退出状态 */
.slide-left-leave-active {
  animation: center2left 400ms forwards;
  z-index: 1;
}
/*路由后退，上一页进入状态*/
.slide-right-enter-active {
  animation:left2center 400ms forwards;
  z-index: 1;
}
/* 路由后退，下一页退出状态 */
.slide-right-leave-active {
  animation: center2right 400ms forwards;
  z-index: 10;
  box-shadow: -3px 0  5px rgba(0,0,0,.1);
}
/* 路由前进，下一页进入 */
.slide-left-enter-active {
  animation: right2center 400ms forwards;
  z-index: 10;
  box-shadow: -3px 0  5px rgba(0,0,0,.1);
}
@keyframes right2center {
  from {
    transform: translate3d(100%, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes center2left {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0.5;
    transform: translate3d(-20%, 0, 0);
    /* left:-20%; */
  }
}
@keyframes left2center {
  from {
    opacity: .5;
    transform: translate3d(-20%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes center2right {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(100%, 0, 0);
    opacity: 1;
  }
}
.toolbar~.container {
  border-bottom: 5rem solid transparent;
}

.container, .main {
  position: relative;
  background: #efeff4;
  transition: transform .4s;
}
.m-mask {
  margin: 0!important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
  background: rgba(0,0,0,.5);
}
/* --------loading ------*/
.loading {
  background: rgba(0, 0, 0, 0.6);
}
.loading-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0 !important;
  padding: 0.4rem 0.5rem;
  min-width: 6rem;
  min-height: 7rem;
  max-width: 8rem;
  text-align: center;
  font-size: 12px;
  background-color: #fff;
  border-radius: 0.5rem;
  color: #888888;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
}
.loading-box p:first-child {
  margin-top: 26px;
}

.loading-box img {
  margin-bottom: 0.5rem;
  width: 90%;
}
.wxloading {
  opacity: 0;
  margin: 0!important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0,0,0,.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.wxloading .wxloading-white {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.wxloading .wxloading-main {
  width: 90%;
  height: 26.5rem;
  background: url("https://cdn.kuaidi100.com/images/m/dispatch/bg_wx.png") no-repeat;
  background-size: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.wxloading .wxloading-main .wxloading-main-code {
  margin-top: 2.5rem;
  width: 14.75rem;
  z-index: 30;
}
.wxloading-main-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 44px;
  width: 100%;
  background-color: #dedede;
  color: #888;
  text-align: center;
  font-size: 13px;
  padding-top: 8px;
  border-radius: 6px;
}
.wxloading-main-bottom img{ 
  width: 0.875rem;
  height: 1.25rem;
}
</style>
