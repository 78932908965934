<template>
  <div class="danmu-container" :style="{background:background}">
    <div class="danmu-box" v-if="showItem">
      <div class="box-user">
        <span class="box-description" v-if="danmuItem.description">{{danmuItem.description}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "danmu",
  props: {
    background: {
      type: String,
      default:
        "url('https://cdn.kuaidi100.com/images/m/activity/618/danmu-back.png') center no-repeat",
    },
    danmuData: {
      type: Array,
      defalt: [],
    },
  },
  data() {
    return {
      showItem: false,
      danmuItem: "",
    };
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.newList = this.danmuData.sort(() => {
        return Math.random() > 0.5 ? -1 : 1;
      });
      this.startScrool();
    }, 1500)
  },
  methods: {
    startScrool() {
      let curIndex = 0;
      let len = this.newList.length;
      this.danmuItem = this.newList[0];
      this.showItem = true;
      this.timer = setInterval(() => {
        this.danmuItem = this.newList[++curIndex % len];
      }, 2500);
    },
  },
};
</script>

<style scoped>
.danmu-container {
  width: 16rem;
  height: 1.3rem;
  border-radius: 0.8rem;
  line-height: 1.3rem;
}
.danmu-box {
  left: 0.5rem;
  display: flex;
  justify-content: center;
  animation: scrollInOut 2.5s linear infinite;
  -webkit-animation: scrollInOut 2.5s linear infinite;
}
.box-user {
  height: 100%;
}
.box-description {
  margin: auto 0.3rem;
  font-size: 0.75rem;
  color: #944305;
  font-weight: bold;
}
@keyframes scrollInOut {
  from {
    transform: translate(0, 0.3rem);
    opacity: 0;
  }
  30% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -0.6rem);
    opacity: 0;
  }
}

@-webkit-keyframes scrollInOut {
  from {
    transform: (0, 0.3rem);
    opacity: 0;
  }
  30% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -0.6rem);
    opacity: 0;
  }
}
</style>