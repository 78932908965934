import Vue from "vue"
import Loading from "./Loading.vue"

let loadingInstance //loading为单例模式

Loading.API = function(text) {
  let options = {
    show: true,
    content: text
  }
  loadingInstance && hide()
  loadingInstance = new Vue({
    render: h => {
      return h(Loading, {
        props: options
      })
    }
  })
  loadingInstance.$mount()
  document.body.appendChild(loadingInstance.$el)
  function hide() {
    if(loadingInstance) {
      loadingInstance.$destroy()
      document.body.removeChild(loadingInstance.$el)
      loadingInstance = null
    }   
  }
  return {
    hide
  }
  
}

export default Loading