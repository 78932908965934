import util from "lib/util"

export default {
  name: "courier",
  components: {
  },
  data() {
    return {
      swiperOption: {
        
      },
      gridlist: [
        {
          desc: "优选上门取件",
          logo: "https://cdn.kuaidi100.com/images/better/send.png",
          name: "寄快递",
          sign: "kdbest",
          tips: "丢必赔"
        },
        {
          desc: "30kg+",
          logo: "https://cdn.kuaidi100.com/images/better/big.png",
          name: "寄大件",
          sign: "iYOhlTA756OzMarket",
          tips: ""
        },
        {
          desc: "1小时达",
          logo: "https://cdn.kuaidi100.com/images/better/same.png",
          name: "同城急送",
          sign: "SFCitySentMarket",
          tips: ""
        },
        {
          desc: "专业寄全球",
          logo: "https://cdn.kuaidi100.com/images/better/global.png",
          name: "国际件",
          sign: "intSentMarket",
          tips: ""
        },
        {
          desc: "快递柜寄件",
          logo: "https://cdn.kuaidi100.com/images/better/kdg.png",
          name: "快递柜寄件",
          sign: "intSentMarket",
          tips: ""
        },
        {
          desc: "商家寄件",
          logo: "https://cdn.kuaidi100.com/images/better/shangjia.png",
          name: "商家寄件",
          sign: "intSentMarket",
          tips: "3.5元寄全国"
        },
        /* {
          desc: "更多寄件服务，敬请期待",
          enAble: "N",
          logo: "https://cdn.kuaidi100.com/images/better/more.png",
          more: "Y",
          name: "敬请期待",
          tips: "",
        } */
      ],
      markets: [],
      couriers: [],
      dispatchs: [],
      position: {
        lat: util.query("lat") || '',
        lng: util.query("lng") || '',
        status: false
      },
      banner: {
        show: false,
        has: false,
        data: {}
      },
      // showLoading: true,
      locating: true,
      locateInfo: {
        city: util.query('city'),
        district: '',
        province: '',
        code: ''
      },
      stampid: util.query('stampid'),
      siid: util.query("siid"),
      linkSource: util.query("linkSource"),
      optor: util.query("optor"),
      keyword: '',
      orderSource: util.query("orderSource") || this.$store.state.globalData.coname || this.$store.state.globalData.from || "m",
      citySent: {
        desc: {
          type: 'row',
          text: ['解决<strong>紧急递送</strong>需求，非普通快递', '专人即取即送，平均<strong>1小时送达</strong>', '按距离收费']
        },
        show: false
      },
      businessSent: {
        desc: {
          type: 'row',
          text: ['提供商家<strong>超低价</strong>寄件服务', '梯度价优惠，<strong>最低3.5元寄全国</strong>', '专人客服，当日件当日揽']
        },
        show: false
      },
      showGLobal: false,
      showZng: false,
      doingListData: {},
      guideObj: {
        url: '',
        showDesc: false,
        show: false,
        text: [],
        title: '',
      },
      sntObj: {
        url: 'https://cdn.kuaidi100.com/images/m/dispatch/businesssend.png', 
        showDesc: true, 
        show: true,
        title: '什么是商家寄件',
        text: [
          '<span>提供商家</span><span class="orange">超低价</span><span>寄件服务</span>',
          '<span>梯度价优惠，</span><span class="orange">最低3.5元寄全国</span>',
          '<span>专人客服，当日件当日揽</span>'
        ]
      },
      cityObj: {
        url: 'https://cdn.kuaidi100.com/images/qrCode/cityorder.png', 
        showDesc: true, 
        show: true,
        title: '什么是同城急送',
        text: [
          '<span>解决</span><span class="orange">紧急递送</span><span>需求，非普通快递</span>',
          '<span>专人即取即送，平均</span><span class="orange">一小时送达</span>',
          '<span>按距离收费</span>'
        ]
      },
      kingdeeObj: {},
      done: false,
      hasData: false
    }
  },
  computed: {
    xzqname: function() {
      return this.locateInfo.province + this.locateInfo.city + this.locateInfo.district
    },
    hideTab: function() {
      return this.$store.state.globalData._sg || ['hengda', 'pingan', '56duan'].includes(this.$store.state.globalData.coname) || this.$store.state.globalData.isBaidu
    }
  },
  methods: {
    indexInfo () { //获取超市
      this.showLoading()
      this.markets = [];
      this.couriers = [];
      this.dispatchs = [];
      this.$http.get('/apicenter/kdmkt.do?method=indexInfoWithoutLocation', {
        data: {
          city: this.locateInfo.city || '',
          province: this.locateInfo.province || ''
        },
        handleFail: false,
        timeout: 5000
      }).then(resultJson => {
        this.hasData = true
        this.markets = resultJson.data || []
        if(Array.isArray(resultJson.gridlist) && resultJson.gridlist.length !== 0 ) {
          this.gridlist = resultJson.gridlist
        }
        let hasKd = false
        if(this.markets.length) {
          for(let i of this.markets) {
            if(i.mktName === '快递100-金蝶软件园店' && i.isinner === 'Y') {
              hasKd = true
              this.kingdeeObj = i
              break
            }
          }
        }
        if(hasKd) { // 有金蝶
          if (this.gridlist[0].name !== '金蝶店') {
            this.gridlist.unshift({
              desc: "",
              logo: "https://cdn.kuaidi100.com/images/better/kingdee.png",
              name: "金蝶店",
              sign: "",
              tips: ""
            })
          }
        } else {
          if (this.gridlist[0].name === '金蝶店') {
            this.gridlist.shift()
          }
        }
        // this.dispatchs = resultJson.dispatch || [];
        // this.couriers = resultJson.coList || [];
        // this.dispatchs.length && util.setSession("supportDispatch", 1)
        // if (this.dispatchs.length && !this.banner.has) {
        //   this.getBanner();
        // } else {
        //   this.banner.show = false;
        // }
        this.done = true
        this.loading.hide()
      }).catch(() => {
        this.banner.show = false
        this.done = true
        this.loading.hide()
        this.hasData = false
      })
    },
    showLoading() {
      this.loading = this.$loading("稍等，让我看看您周边谁能接单")
    },
    goDetail () {
      const data = this.doingListData
      if (data.total > 1) {
        this.$router.push({
          name: 'orderList'
        })
      } else if (data.dispatchid) {
        this.$router.push({
          name: "dispatchDetailIndex",
          query: {
            expid: data.expid,
            dispatchid: data.dispatchid
          }
        })
      } else {
        this.$router.push({
          name: "dispatchOfficial",
          query: {
            expid: data.expid,
            sign: data.sign
          }
        })
      }
    },
    doingList () {
      const url = '/apicenter/order.do?method=doingList'
      this.$http.get(url).then((res) => {
        this.doingListData = res.data[0] || {}
      })
    },
    goHref (url) {
      location.href = url
    },
    closeMask () {
      this.guideObj.show = false
      this.$forceUpdate()
    },
    goUrl (item) { // 跳转url
      console.log(154, item ,'服务类型')
      if(item.enAble !== 'Y') {
        if (item.name === '敬请期待') {
          return this.$toast('更多寄件服务，敬请期待')
        } else {
          return this.$toast(item.unablemsg || '当前所在城市暂未开通该服务，敬请期待')
        }
      }
      if (item.name === '寄快递') {
        const params = {
          roletype: 'KD100BEST'
        }
        this.goMarket(params)
      } else if(item.name === '同城急送') {
        this.showGuide(this.cityObj)
      } else if (item.name === '商家寄件') {
        this.showGuide(this.sntObj)
      } else if(item.name === '快递柜寄件') {
        this.showGuide({url: 'https://cdn.kuaidi100.com/images/m/qrcodes/mini_zng.png?version=2', showDesc: false, show: true, text: [], title: '',})
      } else if (item.name === '寄大件') {
        this.goMarket(item, true)
      } else if (item.name === '国际件') {
        this.goService('global')
      } else if (item.name === '金蝶店') {
        this.goMarket(this.kingdeeObj)
      }/* else if (item.name === '敬请期待') {
        this.$toast('更多寄件服务，敬请期待')
      } */
    },
    showGuide (obj) {
      this.guideObj = JSON.parse(JSON.stringify(obj))
    },
    setLocateInfo: function(data) { //格式化地址信息
      if(data && data.addressComponent) {
        this.locateInfo.city = data.addressComponent.city || data.addressComponent.province //获取城市名
        this.locateInfo.province = data.addressComponent.province
        this.locateInfo.district = data.addressComponent.district
        if (data.formattedAddress) {
          this.keyword = data.formattedAddress.replace(this.xzqname, '') || data.formattedAddress //关键词，详细地址
        }
      } else {
        this.keyword = "点击此处修改地址"
      }
      
      this.position.lat = (data.position || data.location || {}).lat || this.position.lat
      this.position.lng = (data.position || data.location || {}).lng || this.position.lng
      this.position.status = true
      this.locating = false
      this.login(() => {
        this.indexInfo()
      }, () => {
        this.indexInfo()
      } )
      util.setStorage("lastLocate", data)
    },
    reLocate: function() {//重新定位
      this.locating = true
      this.showLoading()
      this.locateInfo.city = this.locateInfo.district = this.locateInfo.province = this.keyword = '';
      this.position.lng = this.position.lat = '';
      this.couriers = []
      this.markets = []
      this.dispatchs = []
      this.invokeLocate(true)
    },
    transAddresss: function() {//传入了经纬度，则先转化，转化失败则定位
      util.map.plugin(['AMap.Geocoder'], () => {
        this.geocoder = new AMap.Geocoder()
        this.geocoder.getAddress([this.position.lng, this.position.lat], (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            this.setLocateInfo(result.regeocode || {}, true);
          } else {
            util.locate({
              success: (position, data) => {
                this.position.lat = position.lat
                this.position.lng = position.lnt
                this.setLocateInfo(data)
              },
              fail: this.locateFail
            }, this)
          }
        })
      })
    },
    invokeLocate: function(force) {//定位
      // this.showLoading = true
      this.showLoading()
      this.locating = true
      if(this.$route.params.locateInfo && (this.$route.params.locateInfo.position || this.$route.params.locateInfo.location)) {
        this.setLocateInfo(this.$route.params.locateInfo)
        this.$store.commit("setLocate", this.$route.params.locateInfo)
        util.setSession("sessionLocate", this.$route.params.locateInfo.position)
        util.setSession("sessionLocateInfo", this.$route.params.locateInfo)
      } else if(this.position.lat && this.position.lng) {
        this.transAddresss()
      } else { 
        util.locate({
          success: (position, data) => {
            this.position.lat = position.lat
            this.position.lng = position.lnt
            this.setLocateInfo(data)
          },
          fail: this.locateFail
        }, this, !!force)
      }
    },
    locateFail: function(data) { //定位失败
      let lastLocate = util.getStorage("lastLocate")
      if (lastLocate && lastLocate.position) {
        this.setLocateInfo(lastLocate)
        this.$toast(data && data.message.indexOf('permission denied') != -1 ? "没有定位权限，已切换至上次定位" : "定位失败，已切换至上次定位")
      } else {
        this.$toast(data && data.message.indexOf('permission denied') != -1 ? "未开启定位权限，请手动选择地址" : "定位失败，请手动选择地址")
        this.showSearchLayer()
        this.locating = false;
        this.position.lat = this.position.lng = ''
        this.position.status = false
        // this.showLoading = false
        this.loading.hide()
      }
      try{
        this.addlog((this.$store.state.globalData.coname || 'm') + "_" + "locateFail", JSON.stringify(data || {}))
      }catch (e){
        this.addlog("locateFail")
      }
    },
    setPosition: function() { //识别地址
      this.locating = true;
      this.markets = [];
      this.couriers = [];
      let address = this.xzqname + this.areas.keyword;
      this.geocoder.getLocation(address, (status, result) => {
        if (status == 'complete' && result.geocodes.length) {
          var data = result.geocodes[0];
          this.setLocateInfo(data);
        } else {
          this.$toast("地址解析失败")
          this.locating = false;
          this.position.lat = this.position.lng = '';
          this.position.status = false;
          this.loading.hide()
        }
      })
    },
    getBanner: function() {
      this.$http.get('/assets/ext', {
        data: {
          method: 'mainprofile',
          platform: 'm',
          pos: 'm_orderindex_banner'
        },
        handleFail: false
      }).then(res => {
        if (res.adslist || res.adslist.length) {
          let query = this.$store.state.globalData.queryString
          this.banner.data = res.adslist[0]
          if (this.banner.data) {
            this.banner.data.url += `?dispatchId=${this.dispatchs[0].id}&lat=${this.position.lat}&lng=${this.position.lng }${(query ? "&" + query : "")}`
            this.banner.show = true;
          }
        }
      })
    },
    goMarket: function(market, isHeavy) { //跳转到寄件页
      let query = {
          // lat: this.position.lat,
          // lng: this.position.lng,
          orderSource: this.orderSource
        }, pathName
      if (market.roletype == "KD100BEST") {
        util.setSession("gotaddr",{
          addr: this.keyword,
          xzq: this.xzqname
        })
        pathName = "dispatch"
        query.dispatchId = market.id || 0
      }  else if(market.roletype === 'ZNG') {
        this.goService('zng');
        return
      } else {
        // if (market.status != 'OPEN') {
        //   this.$toast("当前所在城市暂未开通该服务，敬请期待")
        //   return
        // }
        pathName = 'normal'
        query.sign = market.sign || ""
      }
      util.query("couponId") && (query.couponId = util.query("couponId"))
      util.query("prizeCode") && (query.prizeCode = util.query("prizeCode"))
      this.stampid && (query.stampid = this.stampid)
      this.siid && (query.siid = this.siid)
      this.linkSource && (query.linkSource = this.linkSource)
      this.optor && (query.optor = this.optor)
      isHeavy && (query.heavy = 1)
      this.$router.push({
        name: pathName,
        query: query
      })
    },
    goCourier: function(guid) { //跳转到快递员详情
      let query = this.$store.state.globalData.queryString
      if (guid) {
        location.href = 'https://m.kuaidi100.com/courier/detail_' + guid + '.html' + (query ? `?${query}` : "")
      }
    },
    showSearchLayer: function() {
      this.navigator.push({
        name: "location",
        params: {
          showPicker: true,
          locateInfo: this.locateInfo,
          keyword: this.keyword,
          source: "courier"
        }
      })
    },
    initAppGuide() {
      const isNotfirstEnter = util.getStorage("firstCourier") || false;
      if(!isNotfirstEnter) {
        this.appGuide()
        util.setStorage("firstCourier", Date.now())
      }
    },
    appGuide() {
      window.AppGuide.dialog({
        logtype: 'firstCourierEnter',
        wxDisable: true,
        plainBtn: {
          text: "继续使用"
        },
        iosLink: "ilovegirl/near?selectIndex=2&ordersource=mweb",
        androidLink: "ilovegirl/near?source=mweb",
        miniprogram: {
          path: "pages/orderindex/index",
          orderSrouce: "mweb"
        },
        quickapp: {
          path: "orderindex",
          query: {
            channel: "mweb"
          }
        },
      })
      /* window.AppGuide.dialog({
        downloadText: "使用APP打开",
        wxDisable: true,
        logtype: 'firstCourierEnter',
        iosLink: "ilovegirl/near?selectIndex=2&ordersource=mweb",
        androidLink: "ilovegirl/near?source=mweb",
        miniprogram: {
          path: "pages/orderindex/index",
          orderSrouce: "mweb"
        },
        quickapp: {
          path: "orderindex",
          query: {
            channel: "mweb"
          }
        },
        wechat: {
          openText: '打开小程序',
          title: '打开微信小程序，享受更顺畅的寄件服务',
          hideSubtitle: true,
          subtitle: '',
          link: 'weixin://dl/business/?t=7PInSLvtZgu'
        }
      }) */
    },
    goService(type) {
      const AppGuide = window.AppGuide
      if(type === 'global') { // 新增国际件的运营
        location.href = "https://mgj.kuaidi100.com/activity/globalcouponm.jsp?source=mzsy&cardtype=CARD_TYPE_DISPATCH_LWUXTC"
      } else if(AppGuide.context === 'wechat') {
        if(type === 'city') this.citySent.show = true;
        if(type === 'global') this.showGLobal = true;
        if(type === 'zng') this.showZng = true;
        this.$forceUpdate()
      } else {
        this.appGuide()
      }
    }
  },
  mounted: function() {
    // this.invokeLocate()
    this.initAppGuide()
    ;(function(a,h,c,b,f,g){a["UdeskApiObject"] = f;a[f] = a[f] || function(){(a[f].d = a[f].d || []).push(arguments)};g = h.createElement(c);g.async = 1;g.charset = "utf-8";g.src = b;c = h.getElementsByTagName(c)[0];c.parentNode.insertBefore(g,c)})(window,document,"script","//assets-cli.udesk.cn/im_client/js/udeskApi.js","ud");
    ud({
      "code": "298f17ga",
      "link": "https://kuaidi100.udesk.cn/im_client/?web_plugin_id=48852",
      targetSelector: "#goOnlineService"
      // selector: "#goOnlineService" // "#uDeskTarget"
    });
    this.login(() => {
      this.indexInfo()
      this.doingList()
    }, () => {
      this.indexInfo()
    } )
    // if(!localStorage.getItem('isFirst')) {
    //   AppGuide.dialog({
    //     title: '打开微信小程序，享受更顺畅的寄件服务',
    //     logtype: 'indexScanDialog',
    //     wechat: {
    //       openText: '打开小程序',
    //       title: '打开微信小程序，享受更顺畅的寄件服务',
    //       hideSubtitle: true,
    //       subtitle: '',
    //       link: 'weixin://dl/business/?t=7PInSLvtZgu'
    //     }
    //   })
    //   localStorage.setItem('isFirst', 1)
    // }
  },
  activated () {
    // if(this.$route.params.reLocate) {
    //   this.reLocate()
    // } else if(this.$route.params.locateInfo && (this.$route.params.locateInfo.position || this.$route.params.locateInfo.location)) {
    //   this.invokeLocate()
    // }
    this.$store.dispatch("removeKeepAliveRoutes")
    // this.share()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(vm.$store.state.globalData.isBaiduApp) {
        util.loadScript("https://b.bdstatic.com/searchbox/icms/searchbox/js/swanInvoke.js", function() {
          window.swanInvoke({
            appKey: 'a437DMFSxRxbWjGhwDDYlpxqIjYI2goB',
            path: 'pages/orderindex/index',
            query: {
              orderSource: "h5_courier_jump"
            }
          })
        })
      }
    })
  }
}