<template>
  <div class="wrap" v-if="ad._id" :style="styl">
    <a target="_blank" :href="ad.url">
      <img :src="ad.bgimage" @click="statistics('click')">
    </a>
    <div class="close" v-if="ad.showType != '活动'" @click="close">广告</div>
  </div>
</template>
<script>
import util from "lib/util"
export default {
  props: ["pos", "styl"],
  data() {
    return {
      ad: {}
    }
  },
  methods: {
    load() {
      this.$http.axios('/assets/ext?method=mainprofile', {
        params: {
          platform: 'm',
          pos: this.pos,
          coname: this.$store.state.globalData.coname || ""
        },
        handleFail: false
      }).then(res => {
        //随机取一条广告
        res = res.data
        this.ad = res.adslist[Math.floor(Math.random() * (res.adslist.length -1))] || {}
        this.statistics()
      })
    },
    statistics(type) {
      this.$http.post('/mainapi.do?method=vieweventads', {
        data: {
          source: "m",
          adlocation: this.pos,
          adurl: encodeURIComponent(this.ad.url) || 'UNKNOWN',
          showorlink: type || "show",
          _id: this.ad._id
        },
        handleFail: false   
      })
    },
    close() {
      var refuseDate = util.getStorage("adRefuseDate") || {}
      refuseDate[this.pos] = Date.now()
      util.setStorage('adRefuseDate', refuseDate)
      this.statistics("close") 
      this.ad = {}
    }
  },
  created() {
    if(!this.pos) return
    let refuseDate = (util.getStorage("adRefuseDate") || {})[this.pos] || 0
    let expire = Date.now() - 24 * 60 * 60 * 1000 // 24小時前
    if(+refuseDate < expire) {
      this.load()
    }
  }
}
</script>
<style scoped>
.wrap {
  margin: 10px;
  position: relative;
}
a, .close {
  cursor: pointer;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  color: #FFF;
  background: rgba(0,0,0,.5);
  font-size: .75rem;
  padding: 0 .5em;
}
.close::after {
  display: inline-block;
  vertical-align: -1px;
  transform: rotate(45deg);
  font-size: .875rem;
  text-align: center;
  content: '+';
}
img {
  max-width: 100%;
}
</style>
