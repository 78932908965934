import Toast from "./Toast"
import Loading from "./Loading"
import Confirm from "./Confirm"
import ActionSheet from "./Actionsheet"
import Webview from "./Webview"
import Slide from "./Slide"
import Popup from "./Popup"
import Layout from "./Layout"
import AppGuide from "./AppGuide/Index.vue"
import Ad from "./Ad"
import FeatureModal from "./FeatureModal"
import MiniProgramModal from "./MiniProgramModal"
import Danmu from "./Danmu/Index.vue"
import MaskItem from "./MaskItem/Index.vue"
import DanmuItem from "./DanmuItem/Index.vue"

const components = {
  Toast,
  Loading,
  Confirm,
  ActionSheet,
  Webview,
  Slide,
  Popup,
  Layout,
  AppGuide,
  Ad,
  FeatureModal,
  MiniProgramModal,
  Danmu,
  MaskItem,
  DanmuItem
}
const install = function(Vue) {
  
  if (install.installed) return;

  Object.keys(components).forEach(key => {
    Vue.component(key, components[key])
  })


  Vue.prototype.$toast = Toast
  Vue.prototype.$loading = Loading.API
  Vue.prototype.$confirm = Confirm.API

  install.installed = true
}

export default install
