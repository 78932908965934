<template>
  <div
    v-show="maskShow || ( isLYBVaild==='Y' && !isTakeIn) || guideShow  || gzhAttentionShow || shareShow || timeoverShow || unlotteryShow || lotteryShow || adShow"
    class="mask-container"
    @click="close"
  ></div>
</template>

<script>
export default {
  name: "mask-item",
  data() {
    return {};
  },
  props: {
    maskShow: Boolean,
    isLYBVaild: String,
    isTakeIn: Boolean,
    guideShow: Boolean,
    lotteryShow: Boolean,
    gzhAttentionShow: Boolean,
    shareShow: Boolean,
    timeoverShow: Boolean,
    unlotteryShow: Boolean,
    adShow: Boolean,
  },
  methods: {
    close() {
      this.$emit("closeMask");
    },
  },
};
</script>

<style scoped>
.mask-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.7;
  z-index: 10;
}
</style>