export const danmuData= [
  { num: '【尾号1004】', text: '成功抽中iWatch手表' },
  { num: '【尾号5238】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号8532】', text: '成功成功抽中寄件优惠券' },
  { num: '【尾号8624】', text: '成功抽中寄件优惠券' },
  { num: '【尾号8562】', text: '成功抽中iWatch手表' },
  { num: '【尾号6282】', text: '成功抽中坚果投影仪' },
  { num: '【尾号7512】', text: '成功抽中坚果投影仪' },
  { num: '【尾号3624】', text: '成功抽中寄件优惠券' },
  { num: '【尾号0320】', text: '成功抽中iWatch手表' },
  { num: '【尾号0364】', text: '成功抽中坚果投影仪' },
  { num: '【尾号1236】', text: '成功抽中坚果投影仪' },
  { num: '【尾号2369】', text: '成功抽中iWatch手表' },
  { num: '【尾号3856】', text: '成功抽中寄件优惠券' },
  { num: '【尾号4563】', text: '成功抽中寄件优惠券' },
  { num: '【尾号5896】', text: '成功抽中iWatch手表' },
  { num: '【尾号6138】', text: '成功抽中坚果投影仪' },
  { num: '【尾号7592】', text: '成功抽中坚果投影仪' },
  { num: '【尾号8623】', text: '成功成功抽中寄件优惠券' },
  { num: '【尾号9232】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号0326】', text: '成功抽中iWatch手表' },
  { num: '【尾号1985】', text: '成功抽中坚果投影仪' },
  { num: '【尾号2365】', text: '成功抽中寄件优惠券' },
  { num: '【尾号3652】', text: '成功抽中iWatch手表' },
  { num: '【尾号4203】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号5032】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号6852】', text: '成功抽中iWatch手表' },
  { num: '【尾号7562】', text: '成功抽中坚果投影仪' },
  { num: '【尾号8622】', text: '成功抽中坚果投影仪' },
  { num: '【尾号9522】', text: '成功抽中寄件优惠券' },
  { num: '【尾号0623】', text: '成功抽中iWatch手表' },
  { num: '【尾号1296】', text: '成功成功抽中寄件优惠券' },
  { num: '【尾号2652】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号3562】', text: '成功抽中坚果投影仪' },
  { num: '【尾号4581】', text: '成功抽中坚果投影仪' },
  { num: '【尾号5123】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号6942】', text: '成功抽中坚果投影仪' },
  { num: '【尾号7522】', text: '成功抽中坚果投影仪' },
  { num: '【尾号8233】', text: '成功抽中寄件优惠券' },
  { num: '【尾号9453】', text: '成功抽中iWatch手表' },
  { num: '【尾号0365】', text: '成功抽中坚果投影仪' },
  { num: '【尾号1552】', text: '成功成功抽中寄件优惠券' },
  { num: '【尾号2636】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号3642】', text: '成功抽中寄件优惠券' },
  { num: '【尾号4632】', text: '成功抽中iWatch手表' },
  { num: '【尾号5632】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号6822】', text: '成功成功抽中寄件优惠券' },
  { num: '【尾号7523】', text: '成功抽中坚果投影仪' },
  { num: '【尾号8623】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号9233】', text: '成功抽中寄件优惠券' },
  { num: '【尾号8541】', text: '成功抽中iWatch手表' },
  { num: '【尾号9132】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号0636】', text: '成功成功抽中寄件优惠券' },
  { num: '【尾号1362】', text: '成功抽中寄件优惠券' },
  { num: '【尾号2623】', text: '成功抽中坚果投影仪' },
  { num: '【尾号3543】', text: '成功抽中寄件优惠券' },
  { num: '【尾号4952】', text: '成功抽中iWatch手表' },
  { num: '【尾号5943】', text: '成功抽中iWatch手表' },
  { num: '【尾号6303】', text: '成功抽中iWatch手表' },
  { num: '【尾号7000】', text: '成功抽中坚果投影仪' },
  { num: '【尾号8300】', text: '成功抽中寄件优惠券' },
  { num: '【尾号9349】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号0023】', text: '成功抽中坚果投影仪' },
  { num: '【尾号1123】', text: '成功抽中寄件优惠券' },
  { num: '【尾号2253】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号3463】', text: '成功成功抽中寄件优惠券' },
  { num: '【尾号4836】', text: '成功抽中iWatch手表' },
  { num: '【尾号5074】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号6842】', text: '成功抽中寄件优惠券' },
  { num: '【尾号7620】', text: '成功成功抽中寄件优惠券' },
  { num: '【尾号8231】', text: '成功抽中坚果投影仪' },
  { num: '【尾号9532】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号0522】', text: '成功抽中iWatch手表' },
  { num: '【尾号1621】', text: '成功抽中iWatch手表' },
  { num: '【尾号2613】', text: '成功抽中坚果投影仪' },
  { num: '【尾号3622】', text: '成功抽中寄件优惠券' },
  { num: '【尾号4522】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号5622】', text: '成功抽中坚果投影仪' },
  { num: '【尾号6852】', text: '成功抽中iWatch手表' },
  { num: '【尾号7522】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号8624】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号9532】', text: '成功抽中iWatch手表' },
  { num: '【尾号0618】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号1622】', text: '成功抽中iWatch手表' },
  { num: '【尾号2145】', text: '成功抽中坚果投影仪' },
  { num: '【尾号3654】', text: '成功抽中iWatch手表' },
  { num: '【尾号4524】', text: '成功抽中寄件优惠券' },
  { num: '【尾号5622】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号6852】', text: '成功抽中寄件优惠券' },
  { num: '【尾号7529】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号8452】', text: '成功抽中iWatch手表' },
  { num: '【尾号9003】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号0259】', text: '成功抽中坚果投影仪' },
  { num: '【尾号1352】', text: '成功抽中iWatch手表' },
  { num: '【尾号2820】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号3112】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号4104】', text: '成功抽中iWatch手表' },
  { num: '【尾号5282】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号6852】', text: '成功抽中坚果投影仪' },
  { num: '【尾号7410】', text: '成功抽中2000元京东E卡' },
  { num: '【尾号8520】', text: '成功抽中坚果投影仪' }
]