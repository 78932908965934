<template>
  <popup :show="show">
    <div class="poster">
      <div class="body">
        <div class="code">
          <img :src="code"> 
          <div class="text">
            <p v-if="isWechat">
              <strong>长按识别二维码</strong>后{{ featureText }}
            </p>
            <template v-else>
              <p>截图或保存图片</p><p><strong>微信扫一扫</strong>识别二维码后{{ featureText }}</p>
            </template> 
          </div>
        </div>
        <div class="content">
          <h2 :class="{subtitle: titleSize == 'small'}">
            {{ title }}
          </h2>
          <div v-if="typeof desc === 'string'" class="text">
            {{ desc }}
          </div>
          <template v-else>
            <div v-if="desc.type == 'span'" class="content-flex">
              <div v-for="(item, index) in desc.text" :key="index" class="text" v-html="item"></div>
            </div>
            <template v-else>
              <div v-for="(item, index) in desc.text" :key="index" class="item">
                <p v-html="item"></p>
              </div>
            </template>
          </template>
        </div>
      </div> 
      <div class="line"></div> 
      <div class="close" @click="close"></div>
    </div>
  </popup>
</template>
<script>
import util from "lib/util"
export default {
  model: {
    prop: 'show',
    event: 'close'
  },
  props: {
    code: String,
    title: String,
    featureText: {
      type: String,
      default: "立即下单"
    },
    desc: {
      type: [String, Object],
      default: ""
    }, // 单行描述，多行描述（含按列排和按行排{type: 'span|row', text: []}）
    uuid: {
      required: true,
      type: String
    },
    expire: {
      type: [undefined, Number] //hours
    },
    titleSize: String,
    show: Boolean
  },
  data() {
    return {
      isWechat: this.$store.state.globalData.isWechat
    }
  },
  created() {
    let key = `featureModal_${this.uuid}`
    if(typeof this.expire !== 'undefined' && this.expire > 0) {//弹窗在关闭时间内
      if(+util.getStorage(key) + this.expire * 3600 * 1000 > Date.now()) this.$emit("close", false)
    } 
  },
  methods: {
    close() {
      let key = `featureModal_${this.uuid}`
      typeof this.expire !== 'undefined' && this.expire > 0 && util.setStorage(key, Date.now())
      this.$emit("close", false)
    }
  }
}
</script>
<style scoped>
.poster {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
}
.body {
  overflow: hidden;
  border-radius: 5px;
  background: #f6f6f6;
}
.code {
  padding: 1em;
  background: #FFF;
  text-align: center;
  color: #888;
}
.code img {
  width: 55%;
  margin-bottom: 1em;
}
.poster strong {
  color: #ff7f02;
  font-weight: normal;
}
.content {
  padding: 0 1em 1em;
}
.content-flex {
  display: -webkit-flex;
  display: flex;
  justify-content: space-around;
  padding: 1em;
}
.content-flex .text {
  display: -webkit-flex;
  display: flex;
  color: #888;
  font-size: .875rem;
}
.item:before, .content-flex .text::before {
  width: 10px;
  height: 10px;
  margin: 4px 5px 0 0;
  background: url(https://cdn.kuaidi100.com/images/m/dispatch/ico_point.png) no-repeat;
  background-size: 100%;
  content: '';
}
.line {
  height: 26px;
  width: 1px;
  margin: 0 auto;
  background: rgba(255,255,255,.6);
}
.close {
  width: 35px;
  height: 35px;
  margin: 0 auto;
  background: url(https://cdn.kuaidi100.com/images/m/dispatch/ico_close.png) no-repeat;
  background-size: 100%;
}
.item {
  display: -webkit-flex;
  display: flex;
  padding: 0 1em;
  color: #888;
}
.item p {
  padding-bottom: .5em;
}
.text {
  color: #888;
  text-align: center;
}
h2 {
  text-align: center;
  color: #333;
  font-size: 1.25rem;
  line-height: 3;
}
h2.subtitle {
  font-size: 1.125rem;
}
</style>