const Normal = () => import(/*webpackChunkName: 'normal'*/ "views/Order/Normal/Index.vue")
const Contraband = () => import(/*webpackChunkName: 'normal'*/ "views/Order/Dispatch/Index/Cargo.vue")
const Cancel = () => import("views/Order/Cancel/Index.vue")
const OrderHistory = () => import("views/Order/History/Index.vue")
const Detail = () => import("views/Order/Normal/Detail/Index.vue")
const Pay = () => import("views/Order/Normal/Pay/Index.vue")
const Receipt = () => import("views/Order/Normal/Receipt/Index.vue")
const globalPay = () => import("views/Order/Global/pay.vue")

export default [
  {
    path: "/normal",
    name: "normal",
    meta: {
      title: '寄件服务',
      keepAlive: true
    },
    component: Normal,
    beforeEnter(to, from, next) {
      if(to.query.heavy == '1') {
        to.meta.title = "寄大件"
      } else {
        to.meta.title = "寄件服务"
      }
      next()
    }
  }, 
  {
    path: "/normal/detail",
    name: "normalDetail",
    meta: {
      title: "订单详情"
    },
    component: Detail
  },
  {
    path: "/normal/pay",
    name: "normalPay",
    meta: {
      title: "订单支付"
    },
    component: Pay
  },
  {
    path: "/normal/receipt/:expid/:sign/:stampid?",
    name: "normalReceipt",
    meta: {
      title: "电子回单"
    },
    component: Receipt
  },
  {
    path: "/contraband",
    name: "contraband",
    meta: {
      source: true
    },
    component: Contraband
  },
  {
    path: "/cancel",
    name: "cancel",
    component: Cancel
  },
  {
    path: "/order/history",
    name: "orderList",
    meta: {
      title: "我的寄件记录"
    },
    component: OrderHistory,
    beforeEnter(to, from, next) {
      if(to.query.sntSign && to.query.kdNum) {
        to.meta.title = "店铺订单"
      } else {
        to.meta.title = "我的寄件记录"
      }
      next()
    },
  },
  {
    path: "/order/global/pay",
    name: "globalPay",
    component: globalPay
  },
]