/* eslint-disable no-undef */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Http from "lib/axios"
import View from "components"
import "components/index.css"
import { globalData, queryKeys } from "lib/global"
import util from "lib/util"
import broadcast from "lib/broadcast"
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.use(View)
Vue.use(Http)
Vue.use(VueAwesomeSwiper, /* { default global options } */)

Vue.config.productionTip = false
store.commit("setGlobal", globalData)

//第三方、app、小程序、公众号快应用等需要保留query参数，
//beforeEach无法修改路由的query（页面能正常运行，但历史记录会出错）
let navigator = Vue.prototype.navigator = {
  query(query = {}) {
    let data = store.state.globalData
    queryKeys.map(key => {
      typeof data[key] !== 'undefined' && (query[key] = data[key])
    })
    return query
  },
  replace(data) {
    if (typeof data === 'string') data = { name: data }
    data.query = this.query(data.query)
    store.commit("setReplacePage", true)
    router.replace(data)
  },
  push(data) {
    let lastpage = util.getLastPage()
    if (typeof data === 'string') data = { name: data }
    if (lastpage == data.name) {
      router.go(-1)
    } else {
      data.query = this.query(data.query || {})
      router.push(data)
    }
  },
  switch(data) {//与replace的区别在于页面切换动画不一样，同时会清空本地历史栈
    store.commit("setSwitchPage", true)
    this.replace(data)
    store.commit("setSwitchPage", false)
  }
}

Vue.prototype.login = function (callback, fail) {
  // 更改授权的方式，对第三方和微信提供退出的功能，如果后续有问题，可以回滚到当前之前的授权方式
  // @mock product
  //u9f2Hplcft-5BiN9nC0d3fDzYrJl6qB-u9sxjmNqNY4
  //@Mock test
  // var sessionToken = util.getSession("TOKEN") || "-Cu-K-vNTofPE_JMS5GXTBQjBJrAJj3kQd9G27RCRYw"
  // var cookieToken = util.getcookie("TOKEN") || "-Cu-K-vNTofPE_JMS5GXTBQjBJrAJj3kQd9G27RCRYw"
  var sessionToken = util.getSession("TOKEN")
  var cookieToken = util.getcookie("TOKEN")
  var store = this.$store.state.globalData
  if (store.token || sessionToken || cookieToken) {//官方页面或者其他存了TOKEN的合作页面
    this.$store.commit("setGlobal", {
      token: store.token || sessionToken || cookieToken
    })
    util.isFunc(callback) && callback(sessionToken || cookieToken)
  } else if (store.coname && store.appid && store.openid || store.openid && store.isWechat && !store.isMiniProgram) {//第三方合作app或者微信登录
    var data = store.appid ? {
      openid: store.openid,
      appid: store.appid,
      nonce: store.nonce,
      timeStamp: store.timeStamp,
      kd100sign: store.kd100sign
    } : { openid: store.openid }
    this.$http.get('/apicenter/xcx.do?method=getTokenByOpenid', {
      data: data,
      handleFail: false
    }).then(result => {
      if (store.coname && store.appid && store.openid || !store.coname) {//第三方接入用户和快递100公众号登录，则记录会话的本地存储
        util.setSession("TOKEN", result.data)
      } else {//第三方公众号接入且没有接入用户信息的，则记录长期的TOKEN，在个人中心提供退出登录功能供用户自行退出
        util.setcookie('TOKEN', result.data)
      }
      this.$store.commit("setGlobal", {
        token: result.data
      })
      util.isFunc(callback) && callback(result.data)
    }).catch(() => {
      util.isFunc(fail) && fail()
    })
  } else if (store.isYzj) {
    this.$http.get('/apicenter/xcx.do?method=getTokenByYZJTicket', {
      data: { ticket: util.query('ticket') }
    }).then(result => {
      util.setcookie("TOKEN", result.token)//在云之家入口处会清除该token
      this.$store.commit("setGlobal", { token: result.data })
      util.isFunc(callback) && callback(result.token)
    }).catch(() => {
      util.isFunc(fail) && fail()
    })
  } else if (store.coname == 'hengda' && util.query("auth_code")) {
    this.$http.get("/userapi.do?method=grandeAuth", {
      data: {
        code: util.query("auth_code")
      }
    }).then(result => {
      util.setcookie("TOKEN", result.token)
      this.$store.commit("setGlobal", {
        token: result.token
      })
      util.isFunc(callback) && callback(result.token)
    }).catch(() => {
      util.isFunc(fail) && fail()
    })
  } else {//没有任何可登录的条件
    util.isFunc(fail) && fail()
  }
}
Vue.prototype.setToken = function (token) {
  if (store.coname && store.appid && store.openid || !store.coname && store.isWechat) {//第三方接入用户和快递100公众号登录，则记录会话的本地存储
    util.setSession("TOKEN", token)
  } else {//第三方公众号接入且没有接入用户信息的，则记录长期的TOKEN，在个人中心提供退出登录功能供用户自行退出
    util.setcookie('TOKEN', token)
  }
  this.$store.commit("setGlobal", {
    token: token
  })
}
Vue.prototype.goLogin = function (redirect) {
  if (redirect) {
    this.navigator.replace({
      name: "login",
      query: {
        redirect: 1
      }
    })
  } else {
    this.navigator.push({
      name: "login"
    })
  }

}
Vue.prototype.addlog = function (type) {
  this.$http.post("/apicenter/courier.do?method=addlog", {
    handleFail: false,
    data: {
      logtype: type
    }
  })
}

Vue.prototype.share = function (options = {}) {
  console.log(store.state)
  const data = store.state.globalData
  if (!data.isWechat || data.isMiniProgram) return
  util.loadScript("https://res.wx.qq.com/open/js/jweixin-1.2.0.js", () => {
    console.log(0)
    this.$http.get("/weixin/wxjsticketsign.do", {
      data: {
        url: location.href
      }
    }).then(res => {
      res = res.data
      wx.config({
        debug: true,
        appId: 'wx567c983270be6319',
        timestamp: res.timestamp,
        nonceStr: res.nonceStr,
        signature: res.signature,
        jsApiList: ["onMenuShareAppMessage", "onMenuShareTimeline", "hideMenuItems"]
      })
      wx.ready(function () {
        console.log(22)
        function getOption() {
          return {
            title: options.title || "快递100",
            desc: options.desc || "查快递，寄快递，上快递100",
            link: options.url || location.href,
            imgUrl: options.imgUrl || "https://cdn.kuaidi100.com/images/m/2019/200X200.png",
            success: function () {
              //addlog("share2019");
            },
          }
        }
        wx.onMenuShareAppMessage(getOption())
        wx.onMenuShareTimeline(getOption())
      });
    }).catch(() => {
      // alert(JSON.stringify(res))
    })
  })
}
// 消息广播信道，用于更新具有keep-alive的页面状态
Vue.prototype.$broadcastChannel = broadcast

// 全局路由守卫
router.beforeEach((to, from, next) => {
  var state = store.state.globalData
  if (!state.isWechat || state.isMiniProgram) {
    init()
  } else {
    wxAuth(init)
  }
  function init() {
    let source = to.meta.source
    if (source) {
      if (to.params.source || to.query.source || from.params.source) {
        window._hmt.push(['_trackPageview', to.fullPath])
        next()
      } else {
        let lastpage = util.getLastPage()
        if (source !== true && lastpage != source) {
          navigator.replace({
            name: source
          })
        } else {
          lastpage && router.go(-1) || navigator.switch({
            name: "courier"
          })
        }
      }
    } else {
      window._hmt.push(['_trackPageview', to.fullPath])
      next()
    }
  }
})
router.beforeResolve((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else if (to.meta.documentTitle) {
    document.title = to.meta.documentTitle
  } else {
    document.title = '寄快递,在线寄件 - 快递100'
  }
  next()
})

// 全局过滤器
Vue.filter('encryptionPhone', function(val, show) {
  return show ? val : util.hidePhone(val)
})

function wxAuth(callback) {
  var rq = null, rurl = ''
  try {
    /**
     * 针对微信上，有些人会直接将带有openid的链接分享与人，打开后将直接拿到openid获取用户信心，为避免这种情况，需要特殊处理
     * 这里不可以用sessionStorage来处理，因为微信浏览器sessionStorage的实现机制与标准不一致，sessionStorage无法保持，问题已反馈给微信官方，待修复后可切换为sessionStorage更为方便
     * 暂时借助document.referrer来处理
     * 2018-12-12微信已修复该问题，换回sessionStorage，如果有测试到其它机型还有问题需要重新切换为referrer
     */
    rq = util.getSession("wxSign")
    util.setSession("wxSign", Date.now())
    // return
    // if(!openid || !document.referrer){//如果是直接点击链接进入的，认为是分享后的openid
    if (!util.query("openid") || !rq) {
      //该url为使用sessionStorage时
      rurl = location.href.replace(/&?(openid|unionid|subscribe)=[^&]*/g, '')
      //该url为使用referrer时
      // rurl = "https://m.kuaidi100.com/wxauth.jsp?authuri="+encodeURIComponent(location.href.replace('http:',"https:").replace(/&?(openid|unionid|subscribe)=[^&]*/g,''));
      Vue.prototype.$http.post("/weixin/mapping.do?method=buildMapping", {
        data: {
          url: encodeURI(rurl)
        },
        handleFail: false
      }).then(r => {
        location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx567c983270be6319&redirect_uri=http%3A%2F%2Fwx.kuaidi100.com%2Foauth.do&response_type=code&scope=snsapi_base&state=' + r.key + '#wechat_redirect')
      }).catch(() => {
        location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx567c983270be6319&redirect_uri=http%3A%2F%2Fwx.kuaidi100.com%2Foauth.do&response_type=code&scope=snsapi_base&state=' + encodeURIComponent(rurl) + '#wechat_redirect')
      })
    } else {
      callback()
    }
  } catch (e) {
    callback()
  }
}


window.kuaidi100APP = new Vue({
  router,
  store,
  created() {
    this.$http.commonData = this.$store.state.globalData //用于传递通用的请求数据
    this.$http.vm = this
  },
  render: h => h(App)
}).$mount('#app')
