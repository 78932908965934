import Courier from "views/Courier/Index/Index.vue"
// import Location from "views/Courier/Location/Index.vue"
const Location =  () => import(/* webpackChunkName: 'courier' */ "views/Courier/Location/Index.vue")
const NewIndex =  () => import("views/Courier/Index/newIndex.vue")

/**
 * meta 字段说明
 * keepAlive： 是否使用keep-alive，使用的页面支持使用全局broadcastChannel通信
 * title: 标题，会设置浏览器的标题以及页面顶部公共头部的标题，不设置页面不展示公共标题组件
 * isRoot: 为true代表为一级页面，一级页面的路由切换采用replace，其他采用push，一级页面不展示标题组件，展示底部tabbar
 * source: 是否无法直接访问，需要提供source才可进入，一般用于二级页面，source可以使用query和params方式传递
 * 需要source的页面可以使用嵌套路由或者使用Slide组件结合history API替代来实现（参考实名认证）
 * source值为不字符串会优先跳回指定页面，否则会跳转到上一个页面或首页
 */
const Home = () => import("views/Courier/Home/Index.vue")

export default [
  /* {
    path: '/',
    name: "courier",
    component: NewIndex,
    meta: {
      keepAlive: true,
      title: "寄快递,在线寄件 - 快递100",
      isRoot: true // 是否为一级页面，为路由和后续其他服务做成单页备用
    }
  }, */
  {
    path: '/',
    name: "courier",
    component: Courier,
    meta: {
      keepAlive: true,
      title: "寄快递,在线寄件 - 快递100",
      isRoot: true // 是否为一级页面，为路由和后续其他服务做成单页备用
    }
  },
  {
    path: "/location",
    name: "location",
    meta: {
      source: true
    },
    component: Location
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      isRoot: true
    }
  }
  /* {
    path: "/newIndex",
    name: "newIndex",
    component: NewIndex
  }, */
]