/* eslint-disable no-undef */
import Vue from 'vue'
import VueRouter from 'vue-router'
import CourierRouter from "./courier"
import DispatchRouter from "./dispatch"
import SntInviteRouter from "./sntinvite"
import NormalRouter from "./normal"
import AddressRouter from "./address"
import SendbackRouter from "./sendback"
import Assistance618 from "./assistance618"
import CommonRouter from "./common"
import Welfare from "./welfare"
const Login = () => import(/* webpackChunkName: 'login'*/ "views/Login.vue")
const Auth = () => import(/* webpackChunkName: 'auth'*/ "views/Auth/Index.vue")
const NotFound = () => import(/* webpackChunkName: '404'*/ "views/404.vue")

Vue.use(VueRouter)
/* console.log(CommonRouter)
console.log(AddressRouter) */
/**
 * --------meta 字段说明参见CourierRouter说明--------
 */
const routes = [
  ...CourierRouter,
  ...DispatchRouter,
  ...NormalRouter,
  ...AddressRouter,
  ...SendbackRouter,
  ...Assistance618,
  ...Welfare,
  ...CommonRouter,
  ...SntInviteRouter,
  {
    path: "/auth",
    name: "auth",
    component: Auth
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
      keepAlive: true
    },
    component: Login
  }, {
    path: "*",
    name: "notfound",
    meta: {
      keepAlive: true,
      isRoot: true,
      title: "页面不存在"
    },
    component: NotFound
  }
  // {
  // path: '/about',
  // name: 'about',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component:  () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
