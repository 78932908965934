const  List  = () => import(/* webpackChunkName: 'address' */ "views/Address/List/Index.vue")
const Detail = () => import(/* webpackChunkName: 'address' */ "views/Address/Detail/Index.vue")


export default [
  {
    path: '/address',
    name: "addressList",
    meta: {
      source: true,
      title: "地址簿"
    },
    component: List,
    beforeEnter(to, from, next) {
      if(to.query.source !== 'ucenter') {
        to.meta.title = ""
      } else {
        to.meta.title = "地址管理"
      }
      next()
    },
  },
  {
    path: '/address/detail',
    name: "addressDetail",
    meta: {
      source: true
    },
    component: Detail
  }
]