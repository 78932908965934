import Vue from "vue"
import ToastTemplate from "./Toast.vue"

const ToastContructor = Vue.extend(ToastTemplate)

function Toast(options = {}) {
  if(typeof options === 'string') {
    options = {
      content: options
    }
  }
  const toastInstance = new ToastContructor({
    data: options
  })
  toastInstance.vm = toastInstance.$mount()
  document.body.appendChild(toastInstance.vm.$el)
  toastInstance.show = true
}

export default Toast