<template>
  <div class="webview-wrap">
    <iframe :src="src"></iframe>
  </div>
</template>
<script>
export default {
  name: "webview",
  props: ['src']
}
</script>
<style scoped>
  .webview-wrap {
    height: 100%;
    width: 100%;
  }
  iframe {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
  }
</style>