<template>
  <div v-if="show" class="download-nav">
    <div class="close" @click="close"></div>
    <div class="ico"></div>
    <div class="text">
      {{ text }}
    </div>
    <div class="bt" @click="download">
      打开APP
    </div>
  </div>
</template>
<script>
import util from "lib/util"
export default {
  props: {
    text: {
      type: String,
      default: "快递100 APP，查询寄件更轻松"
    },
    type: String,
    src: String
  },
  data() {
    return {
      show: false
    }
  },
  created() {
    let date = new Date()
    //同一个自然日只展示一次
    if(util.getStorage("nav_download_time") == '' + date.getFullYear() + date.getMonth() + date.getDate()) return this.$destroy()
    this.show = true
  },
  beforeDestroy() {
    this.$el && this.$el.parentNode.removeChild(this.$el)
  },
  methods: {
    close() {
      let date = new Date()
      util.setStorage("nav_download_time", '' + date.getFullYear() + date.getMonth() + date.getDate())  
      this.$destroy()
    },
    download() {
      util.openApp(this.src || '', this.type)
    }
  }
}
</script>
<style scoped>

.download-nav {
  display: flex;
  align-items: center;
  height: 3rem;
  background: #052336;
  color: #FFF;
}

.download-nav .close {
  width: 2rem;
  height: 2rem;
  background: url(https://cdn.kuaidi100.com/images/m/icons/download.png) 11px 10px no-repeat;
  background-size: 50px auto;
}

.download-nav .text {
  flex: 1;
  padding: 0 .8em;
  font-size: .75rem;
}

.download-nav .ico {
  width: 2rem;
  height: 2rem;
  background: url(https://cdn.kuaidi100.com/images/m/icons/download.png) -2px -26px no-repeat;
  background-size: 48px auto;
}

.download-nav .bt {
  background: #4187e6;
  padding: 0 1em;
  font-size: .75rem;
  border-radius: 2em;
  line-height: 2em;
  margin-right: 1em;
}
</style>