const CargoInfo =  () => import(/* webpackChunkName: 'courier' */ "views/Common/cargoInfo.vue")
const InviteGlobal =  () => import(/* webpackChunkName: 'courier' */ "views/Common/invite_global.vue")


export default [
  {
    path: "/common/cargoInfo",
    name: "cargoInfo",
    component: CargoInfo
  },
  {
    path: "/common/invite_global",
    name: "InviteGlobal",
    component: InviteGlobal
  }
]