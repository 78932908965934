<template>
  <transition name="popup">
    <div class="view-slide" v-show="show">
      <slot></slot>
    </div>
  </transition>
</template>
<style>
.view-slide {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 11;
}
</style>
<script>
export default {
  name: "slide",
  props: {
    show: Boolean
  }
}
</script>