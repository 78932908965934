import util from "lib/util"

const ua = navigator.userAgent.toLowerCase()
const fromClient = util.query("kdfrom") || util.query("from")
let globalData =  {
  token: "",
  // openid: util.query("openid"),
  // appid: util.query("appid"),
  // nonce: util.query("nonce"),
  // timeStamp: util.query("timeStamp"),
  // kd100sign: util.query("kd100sign"),
  isWechat: ua.match(/MicroMessenger/i) == 'micromessenger',
  isWindowWechat: ua.indexOf("windowswechat") != -1,
  isYzj: ua.match(/Qing\/.*;(iOS|iPhone|Android).*/i),
  isMiniProgram: fromClient == 'miniProgram',
  isQuickApp: fromClient == 'quickApp',
  isApp: fromClient == 'kdios' || fromClient == 'kdandroid' || fromClient == 'app' || ua.indexOf("kuaidi100") > -1,
  isBaidu: /aladdin|alading|alading|openv|baidu_ala/.test(fromClient),
  isBaiduApp: ua.indexOf("baiduboxapp") != -1,
  platform: "MWWW",
  fromClient: fromClient,
  queryString: "", // 跳转到单页应用外保留参数使用
  // from: fromClient,
  // coname: util.query("coname"),
  
  ua: ua,
  _sg: false
}

const queryKeys = ['openid', 'appid', 'nonce', 'timeStamp', 'kd100sign', 'from', 'kdfrom', 'coname']


queryKeys.map(key => {
  let val = util.query(key)
  if(typeof val !== 'undefined') {
    (globalData[key] = val)
    globalData['queryString'] += `${key}=${val}&`
  }
})
globalData['queryString'] = globalData['queryString'].replace(/&$/, '')

const conameKeys = ['a18d1ccf26e169a8960150ca64d62085']
const sg = util.getSession("coname_sg") || util.query("_sg")
if(sg && conameKeys.indexOf(sg) > -1) {
  globalData["_sg"] = true
}

export {globalData, queryKeys}