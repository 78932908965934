<template>
  <div class="view-layout">
    <template v-if="showTitle">
      <div class="common-header">
        <div class="common-back" @click="back"></div>
        <div class="common-title">
          {{ $route.meta.title }}
        </div>
        <div class="common-menu" @click="tglMenu(true)">
          <span></span>
        </div>
      </div>
      <div v-show="showMenuOurter" class="common-menu-list" @click="tglMenu(false)">
        <ul :class="{down: showMenuInner}">
          <li><a @click="jump('index')"><div class="i"></div><div class="txt">回到首页</div></a></li>
          <li><a @click="jump('mexpress')"><div class="i"></div><div class="txt">寄快递</div></a></li>
          <!-- <li><a @click="jump('more.jsp')"><div class="i"></div><div class="txt">更多服务</div></a></li> -->
          <li><a @click="jump('ucenter.jsp')"><div class="i"></div><div class="txt">个人中心</div></a></li>
        </ul>
      </div>
    </template>
    <div class="view-layout-content">
      <slot></slot>
    </div>
    <div v-if="showSwitch" class="toolbar">
      <a id="t-index" :class="{active: $route.name == 'home'}" @click="jump('index')">
        <div class="ico"></div>
        <div class="txt">查快递</div>
      </a>
      <a id="t-courier" :class="{active: $route.name == 'courier'}" @click="jump('courier')">
        <div class="ico"></div>
        <div class="txt">寄快递</div>
      </a>
      <!-- <a v-if="!$store.state.globalData.coname" id="t-more" @click="jump('more.jsp')">
        <div class="ico"></div>
        <div class="txt">更多服务</div>
      </a> -->
      <a id="t-center" class="badge" @click="jump('ucenter.jsp')">
        <div class="ico"></div>
        <div class="txt">我</div>
      </a>
    </div>
    <!-- ios下fixed定位与z-index会有bug，layout组件的内容有fixed定位元素时需要放置到该slot下 -->
    <slot name="outer-slot"></slot>
  </div>
</template>
<script>
export default {
  name: "Layout",
  data() {
    return {
      showTitle: false,
      showMenuOurter: false,
      showMenuInner: false,
      showSwitch: false
    }
  },
  watch: {
    showMenuOurter: function(v) {
      if(v) {
        setTimeout(() =>{
          this.showMenuInner = true
        }, 10)
      }
    },
    showMenuInner(v) {
      if(!v) {
        setTimeout(() => {
          this.showMenuOurter = false
        }, 200)
      }
    }
  },
  created() {
    let data = this.$store.state.globalData
    this.showTitle =  !this.$route.meta.isRoot && this.$route.meta.title && !(data.isYzj || data.isMiniProgram || data.isApp || data.coname)
    this.showSwitch = this.$route.meta.isRoot === true
  },
  methods: {
    tglMenu (v) {
      if(v) {
        this.showMenuOurter = true
      } else {
        this.showMenuInner = false
      }
    },
    back() {
      history.back()
    },
    jump(name) {
      let query = this.$store.state.globalData.queryString
      let url 
      switch (name) {
        case "index":
          url = this.$store.state.globalData.coname ? 'https://m.kuaidi100.com/mexpress/home' : 'https://m.kuaidi100.com/'
          return location.replace(`${url}${query ? '?' + query : ""}`)
        case "courier":
          // if(this.$route.name === "courier") return
          // return this.navigator.switch({
          //   name: "courier"
          // })
          url = this.$store.state.globalData.coname ? 'https://m.kuaidi100.com/price/' : 'https://m.kuaidi100.com/mexpress'
          return location.replace(`${url}${query ? '?' + query : ""}`)
        default: 
          location.replace(`https://m.kuaidi100.com/${name}${query ? '?' + query : ""}`)
      }
    }
  }
}
</script>
<style scoped>
.common-header {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 2.5rem;
  z-index: 5;
  background: #317ee7;
}
.common-back {
  position: relative;
  height: .6em;
  width: .6em;
  border-top: 2px solid #aaa;
  border-left: 2px solid #aaa;
  border-radius: 2px 0 0 0;
  margin: 0 1rem;
  transform: rotate(-45deg);
  border-color: #fff;
}
.common-back:after {
  position: absolute;
  left: -1rem;
  right: -1rem;
  bottom: -10px;
  top: -10px;
  content: '';
}
.common-title {
  flex-grow: 1;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.common-menu {
  padding-right: 1rem;
}
.common-menu span, .common-menu:before, .common-menu:after {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-left: 4px;
  background: #fff;
  border-radius: 50%;
  margin-top: -3px;
  vertical-align: middle;
}
.common-menu:before, .common-menu:after {
  content: '';
}
.common-menu span, .common-menu:before, .common-menu:after {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-left: 4px;
  background: #fff;
  border-radius: 50%;
  margin-top: -3px;
  vertical-align: middle;
}
.common-menu-list {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0,0,0,.1);
}
.common-menu-list ul {
  position: absolute;
  right: .5rem;
  top: 3rem;
  z-index: 1;
  background-color: #FFF;
  color: #333;
  font-size: .875rem;
  line-height: 2.5em;
  border-radius: 4px;
  transition: all .2s;
  transform: scale(0);
  transform-origin: 100% 0;
  box-shadow: 0 0 10px rgba(0,0,0,.3);
}
.common-menu-list ul.down {
  transform: scale(1);
}
.common-menu-list ul:before {
  position: absolute;
  right: 5px;
  top: -5px;
  border-bottom: 5px solid #FFF;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: '';
}
.common-menu-list li {
  list-style-type: none;
  border-bottom: 1px solid #efeff4;
}
.common-menu-list a, .common-menu-list a:hover, .common-menu-list a:visited {
  color: #333;
}
.common-menu-list .i {
  display: inline-block;
  width: 1.5625rem;
  height: 1.5625rem;
  vertical-align: middle;
  margin: 0 .5em;
  background-image: url(https://cdn.kuaidi100.com/images/m/icons/toolbar.png?version=5);
  background-size: 25px auto;
}
.common-menu-list .txt {
  display: inline-block;
  width: 5em;
  vertical-align: middle;
}
.common-menu-list li:nth-child(2) .i {
  background-position: 0 -25px;
}
.common-menu-list li:nth-child(3) .i {
  background-position: 0 -75px;
}
.common-menu-list li:nth-child(4) .i {
  background-position: 0 -50px;
}
.toolbar {
  display: flex;
  height: 3rem;
  border-top: 1px solid #efeff4;
  background: #fff;
}
.toolbar .active {
  color: #3581eb;
}
.toolbar a {
  position: relative;
  flex-grow: 1;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #808080;
  font-size: .875rem;
}
.toolbar a:first-child .ico {
  background-position: 0 0;
}
.toolbar .ico {
  position: relative;
  width: 1.5625rem;
  height: 1.5625rem;
  background-image: url(https://cdn.kuaidi100.com/images/m/icons/toolbar.png?version=5);
  background-size: 25px auto;
}
.toolbar a:nth-child(2) .ico {
    background-position: 0 -25px;
}
.toolbar a.active:nth-child(2) .ico {
  background-position: 0 -125px;
}
.toolbar a:nth-child(3) .ico {
  background-position: 0 -75px;
}
.toolbar a:last-child .ico {
  background-position: 0 -50px;
}
.toolbar a.active:nth-child(1) .ico {
  background-position: 0 -100px;
}
.toolbar .badge .ico:after {
  position: absolute;
  right: -5px;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: red;
  content: '';
}
</style>
