<template>
  <transition name="fade">
    <div class="view-mask" v-if="show" :class="{'view-mask-flex' : flex}" :style="{zIndex: zIndex}" @click="handleClick">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  name: "popup",
  props: {
    flex: Boolean,
    show: Boolean,
    zIndex: {
      type: Number,
      default: 5
    }
  },
  methods: {
    handleClick() {
      this.$emit("click")
    }
  }
  
}
</script>
<style>

</style>