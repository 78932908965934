const Dispatch = () => import(/*webpackChunkName: 'dispatch' */ "views/Order/Dispatch/Index/Index.vue") 
const DispatchProtocol = () => import(/*webpackChunkName: 'dispatch' */ "views/Order/Dispatch/Index/Protocol.vue") 
const ValinsProtocol = () => import(/*webpackChunkName: 'dispatch' */ "views/Order/Dispatch/Index/ValinsProtocol.vue") 
const DispatchDetail = () => import("views/Order/Dispatch/Detail/Index.vue")
const DispatchPay = () => import(/*webpackChunkName: 'pay'*/"views/Order/Dispatch/Pay/Index.vue") 
const DispatchPayNew = () => import(/*webpackChunkName: 'pay'*/"views/Order/Dispatch/Pay/dispatchPay.vue") 
const dispatchSearch = () => import(/*webpackChunkName: 'dispatch'*/"views/Order/Dispatch/Index/search.vue") 
const dispatchDbp = () => import("views/Order/Dispatch/Index/dbp.vue") 
const dispatchDetailIndex = () => import("views/Order/Dispatch/Index/detail/index.vue")
const dispatchPayDetail = () => import("views/Order/Dispatch/Index/detail/payDetail.vue")
const dispatchOfficial = () => import("views/Order/Dispatch/Index/detail/official.vue")
const dispatchOfficialPay = () => import("views/Order/Dispatch/Index/detail/officialPayDetail.vue")
const dispatchbatch = () => import("views/Order/Dispatch/Batch/Index.vue")
const batchSuccess =  () => import("views/Order/Dispatch/Batch/BatchSuccess.vue")
export default [
  {
    path: "/dispatch",
    name: "dispatch",
    meta: {
      title: "寄快递",
      keepAlive: true
    },
    component: Dispatch
  },
  {
    path: "/dispatch/protocal",
    name: "dispatchProtocal",
    meta: {
      source: "dispatch"
    },
    component: DispatchProtocol
  },
  {
    path: "/dispatch/valinsProtocol",
    name: "valinsProtocol",
    meta: {
      source: "dispatch"
    },
    component: ValinsProtocol
  },
  {
    path: "/dispatch/detail",
    name: "dispatchDetail",
    meta: {
      title: "订单详情"
    },
    component: DispatchDetail
  },
  {
    path: "/dispatch/pay",
    name: "dispatchPay",
    meta: {
      title: "订单支付"
    },
    component: DispatchPay
  },
  {
    path: "/dispatch/dispatchPay",
    name: "dispatchPayNew",
    meta: {
      title: "订单支付",
      ignoreAuth: true
    },
    component: DispatchPayNew
  },
  {
    path: "/dispatch/search",
    name: "dispatchSearch",
    meta: {
      title: "输入地址",
      keepAlive: true
    },
    component: dispatchSearch
  },
  {
    path: "/dispatch/dbp",
    name: "dispatchDbp",
    meta: {
      title: "丢必赔",
      keepAlive: true
    },
    component: dispatchDbp
  },
  {
    path: "/dispatch/detail/index",
    name: "dispatchDetailIndex",
    meta: {
      title: "订单详情"
    },
    component: dispatchDetailIndex
  },
  {
    path: "/dispatch/detail/payDetail",
    name: "dispatchPayDetail",
    meta: {
      title: "计费详情",
      keepAlive: true
    },
    component: dispatchPayDetail
  },
  {
    path: "/dispatch/detail/official",
    name: "dispatchOfficial",
    meta: {
      title: "订单详情"
    },
    component: dispatchOfficial
  },
  {
    path: "/dispatch/detail/officialPayDetail",
    name: "dispatchOfficialPay",
    meta: {
      title: "订单详情",
      keepAlive: true
    },
    component: dispatchOfficialPay
  },
  {
    path: "/dispatch/batch",
    name: "dispatchbatch",
    meta: {
      title: "批量寄件",
      keepAlive: true
    },
    component: dispatchbatch
  },
  {
    path: "/dispatch/batchSuccess",
    name: "batchSuccess",
    meta: {
      title: "订单详情",
      keepAlive: true
    },
    component: batchSuccess
  }
]