const SendBack = () => import(/*webapckChunkName: 'sendback'*/'views/Order/Sendback/Index/Index.vue')
const SendBackDispatch = () => import('views/Order/Sendback/Dispatch/Index.vue')

export default [
  {
    name: "sendback",
    path: "/sendback",
    component: SendBack,
    meta: {
      title: "寄快递",
      keepAlive: true
    }
  },
  {
    path: "/sendback/dispatch",
    name: "sendBackDispatch",
    meta: {
      title: "快递100优选服务",
      keepAlive: true
    },
    component: SendBackDispatch
  }
]