<template>
  <transition name="fade" @after-leave="fadeOut" @after-enter="fadeIn">
    <div class="view-toast" v-show="show">{{content}}</div>
  </transition>
</template>
<script>
export default {
  name: "toast",
  data(){
    return {
      content: "",
      time: null,
      show: false
    }
  },
  computed: {
    autoTime: function() {
      return 1000 + Math.max(0, (this.content.length - 8) / 4 * 1000)
    }
  },
  methods: {
    fadeIn() {
      if(this.time !== 0) {
        setTimeout(() => {
          this.show = false
        }, this.time || this.autoTime)
      }
    },
    fadeOut() {
      this.$destroy()
      document.body.removeChild(this.$el)
    }
  },
  created() {
    //show强制初始化为false
    this.show = false
  }
}
</script>