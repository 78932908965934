const state = {
  // 存储模块的状态
  sendman: {},
  recman: {}
}

const getters = {
  // 用于获取模块的状态
  
}

const mutations = {
  // 同步地改变模块的状态
  SET_SENDMAN(state, sendman) {
    console.log('setsendman', sendman);
    state.sendman = sendman
  },
  SET_RECMAN(state, recman) {
    console.log('setrecman', recman);
    state.recman = recman
  }

}

const actions = {
  // 异步地提交变更
}

export default {
  namespaced: true, // 命名空间，这样可以在全局 store 中使用模块
  state,
  getters,
  mutations,
  actions,
}