const SntInvite = () => import(/* webpackChunkName: 'SntInvite' */ "views/Order/SntInvite/Send/index.vue")
const OrderList = () => import(/* webpackChunkName: 'SntInviteOrderList' */ "views/Order/SntInvite/OrderList/index.vue")
const OrderDetail = () => import(/* webpackChunkName: 'SntInviteDetail' */ "views/Order/SntInvite/Detail/index.vue")
const SntSuccess = () => import("views/Order/SntInvite/Send/success.vue") 

export default [
  {
    path: "/sntinvite",
    name: "sntInvite",
    meta: {
      documentTitle: '寄件下单',
      keepAlive: true
    },
    component: SntInvite
  },
  {
    path: "/sntsuccess",
    name: "sntSuccess",
    meta: {
      keepAlive: true
    },
    component: SntSuccess
  },
  {
    path: "/sntinvite/orderlist",
    name: "sntOrderList",
    meta: {
      documentTitle: '订单列表',
      // title: "订单列表",
      keepAlive: true
    },
    component: OrderList
  },
  {
    path: "/sntinvite/orderdetail",
    name: "sntOrderDetail",
    meta: {
      documentTitle: '订单详情',
      // title: "订单详情",
      keepAlive: true
    },
    component: OrderDetail
  }
]