import ActionSheet from "./Actionsheet.vue"

export default ActionSheet

/**
 * v-on:cancel, v-on:confirm
 * slot:header, slot:default
 * leftBtn: {text, color}, rightBtn
 * show-header, mask-closable
 * v-model, value
 */