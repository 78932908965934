const Welfare = () => import(/* webpackChunkName: 'welfare' */ "views/Activities/welfareCener/Index.vue")
const WelfareMyprize = () => import(/* webpackChunkName: 'welfare' */ "views/Activities/welfareCener/myprize.vue")
const WelfareOrder = () => import(/* webpackChunkName: 'welfare' */ "views/Activities/welfareCener/order.vue")
export default [
  {
    path: '/welfare',
    name: "welfare",
    component: Welfare
  },
  {
    path: "/welfare/myprize",
    name: "welfareMyprize",
    component: WelfareMyprize
  },
  {
    path: "/welfare/order",
    name: "welfareOrder",
    component: WelfareOrder
  }
]
