import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import dispatch from './dispatch'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    position: {},// 经纬度
    positionInfo: {}, // formattedAddress === '详细地址'，  addressComponent === 省市区
    globalData: {},
    userAuth: false, // 是否实名
    switchPage: false,
    replacePage: false, //当前切换页面为替换方式
    keepAliveRoutes: [],
    userInfo: {}
  },
  mutations: {
    setLocate(state, data) {
      state.position = data.position
      state.positionInfo = data
    },
    setGlobal(state, data) {
      state.globalData = Object.assign(state.globalData, data)
    },
    setAuth(state, auth) {
      state.userAuth = auth
    },
    setSwitchPage(state, flag) {
      state.switchPage = flag
    },
    setReplacePage(state, flag) {
      state.replacePage = flag
    },
    setKeepAliveRoutes(state, routes) {
      state.keepAliveRoutes = routes.slice(0)
    }
  },
  actions: {
    removeKeepAliveRoutes(context, name) {
      let routes = context.state.keepAliveRoutes
      let index = routes.indexOf(name)
      if(index !== -1) {
        let route = routes.splice(index, 1)
        context.commit("setKeepAliveRoutes", routes)
        setTimeout(() => {
          routes = routes.concat(route)
          context.commit("setKeepAliveRoutes", routes)
        }, 10)
      } else {
        context.commit("setKeepAliveRoutes", [])
        setTimeout(() => {
          context.commit("setKeepAliveRoutes", routes)
        }, 10)
      }
    }
  },
  modules: {
    dispatch
  },
  getters
})
