const ua = navigator.userAgent.toLowerCase()
const mobileReg = /^1\d{10}$/
let util = {
  tips(content, time){
    if(content){
      var html  = $('<div style="position:fixed;width:100%;top:0;bottom:0;text-align:center;z-index:99999;transition:all .3s;padding:0 3.625rem;box-sizing:border-box;"><div style="display:inline-block;vertical-align:middle;background: rgba(0,0,0,.8);color:#FFF;border-radius: 4px;font-size: .875rem;padding: .5rem 1rem;line-height: 1.5em;box-sizing:border-box;">' + content + '</div><span style="display:inline-block;height: 100%;vertical-align:middle;"></span></div>');
      $('body').append(html);
      var hide = function() {
        html.css('opacity',0)
        setTimeout(function(){
          html.remove();
        },500);
      }
      html.click(function() {
        hide();
      });
      setTimeout(function(){
        hide()
      },time || (1000 + (content.length - 10) / 10 * 1000))
    }
  },
  getcookie(cookieName) {
    var cookieValue = "";
    if (document.cookie && document.cookie != '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].replace(/(^\s*)|(\s*$)/g, "");
        if (cookie.substring(0, cookieName.length + 1) == (cookieName + '=')) {
          cookieValue = unescape(cookie.substring(cookieName.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  },
  setcookie(cookieName, cookieValue) {
    var expires = new Date();
    var now = parseInt(expires.getTime())
    var et = (86400 - expires.getHours() * 3600 - expires.getMinutes() * 60 - expires.getSeconds())
    expires.setTime(now + 1000000 * (et - expires.getTimezoneOffset() * 60))
    document.cookie = `${escape(cookieName)}=${escape(cookieValue)};expires=${expires.toGMTString()}";domain=${process.env.VUE_APP_COOKIE_URL};path=/`
  },
  delcookie(name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getcookie(name);
    document.cookie = escape(name) + "=" + escape(cval) + "; expires=" + exp.toGMTString() + "; path=/";
  },
  resetcookie(name) {
    util.setcookie(name, '');
  },
  setLocalStorage_withTime(key, value, days) {
    // 设置过期原则
    if (!value) {
        localStorage.removeItem(key)
    } else {
        var Days = days || 1; // 默认保留1天
        var exp = new Date();
        localStorage[key] = JSON.stringify({
            value,
            expires: exp.getTime() + Days * 24 * 60 * 60 * 1000
        })
    }
  },
  getLocalStorage_withTime(key) {
    if (localStorage[key]) {
      let o = JSON.parse(localStorage[key])
      if (o.expires < Date.now()) {
        return false
      } else {
        return o.value
      }
    } else {
      return false
    }
  },
  getStorage(name) {
    var value = null;
    try{
      value = JSON.parse(localStorage.getItem(name))
    } catch(e){}
    return value;
  },
  setStorage(name,value){
    try{
      localStorage.setItem(name,JSON.stringify(value))
    } catch(e){}
  },
  getSession(name) {
    var value = null
    try{
      value = JSON.parse(sessionStorage.getItem(name))
    } catch(e) {}
    return value
  },
  setSession(name,value) {
    try{
      sessionStorage.setItem(name,JSON.stringify(value))
    }catch(e){}
  }, 
  query(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
    var r = window.location.search.substr(1).match(reg)
    if (r != null) return decodeURIComponent(r[2])
    return undefined
  },
  map: new AMap.Map('mapContainer'),
  locate(callback, target, force) {//回调，vue组件实例，是否强制重新定位 
    let position = target.$store.state.position || {}
    let sesstionPosition = util.getSession("sessionLocate") || {}
    // position:经纬度; positionInfo: 位置额外信息
    if(!force && position.lat && position.lng) {
      callback.success(position, target.$store.state.positionInfo || {})
    } else if(!force && sesstionPosition.lat && sesstionPosition.lng) {//从其他页面缓存过来的经纬度
      target.$store.commit("setLocate", util.getSession("sessionLocateInfo"))
      callback.success(sesstionPosition, util.getSession("sessionLocateInfo") || {})
    } else {
      let geolocation = null;
      util.map.plugin(['AMap.Geolocation'], function() {
        geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 3000, //超过3秒后停止定位，默认：无穷大
          showButton: false, //显示定位按钮，默认：true
          showMarker: false, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: false, //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: false, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: false, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          extensions: 'all'
        })
        AMap.Event.addListener(geolocation, 'complete', function(data) {
          if (data.position && data.position.lng && data.position.lat) {
            callback.success(data.position, data)
            target.$store.commit("setLocate", data)
            util.setSession("sessionLocate", data.position)
            util.setSession("sessionLocateInfo", data)
          } else {
            callback.fail(data)
          }
        });
        util.map.addControl(geolocation)
        AMap.Event.addListener(geolocation, 'error', callback.fail)
        /* AMap.Event.addListener(geolocation, 'error', (data)=> {
          console.log('data', data)
        }) */
        geolocation.getCurrentPosition()
      })
    }
  },
  isFunc(obj) {
    return typeof obj === 'function'
  },
  downApp(){
    if(ua.indexOf("micromessenger") != -1){
      location.href = "http://a.app.qq.com/o/simple.jsp?pkgname=com.Kingdee.Express&ckey=CK1413979100250"
    }else if(ua.indexOf("iphone") != -1 || ua.indexOf("ipad") != -1){
      location.href = "http://itunes.apple.com/app/id458270120?ls=1&mt=8"
    }else if(ua.indexOf("android") != -1){
      location.href = "http://a.app.qq.com/o/simple.jsp?pkgname=com.Kingdee.Express&ckey=CK1413979100250"
    }else{
      location.href = "//m.kuaidi100.com/app/"
    }
  },
  openApp(src, type) {
    const ua = navigator.userAgent.toLowerCase()
    var isIOS = ua.indexOf("iphone") != -1 || ua.indexOf("ipad") != -1
    var isAndroid = ua.indexOf("android") != -1
    var t
    src = src || 'kuaidi100://'
    if (ua.indexOf("micromessenger") != -1) {
      location.href = "http://a.app.qq.com/o/simple.jsp?pkgname=com.Kingdee.Express&ckey=CK1413979100250&" + (isIOS ? "ios_scheme=" : "android_schema=") + encodeURIComponent(src)
    } else if (isIOS) {
      window.location.href = src;
      var clickedAt = +new Date
      setTimeout(function () {
        !window.document.webkitHidden && setTimeout(function () {
          if (+new Date - clickedAt < 2000) {
            window.location = type ? 'https://itunes.apple.com/app/apple-store/id458270120?pt=117859344&ct=' + type.toLowerCase() + '&mt=8' : 'http://itunes.apple.com/app/id458270120?ls=1&mt=8'
          }
        }, 400)
      }, 500)
    } else if (isAndroid) {
      var script = document.createElement('script')
      script.async = "async"
      script.src = "https://127.0.0.1:10100"
      script.onload = function () {
        window.location.href = src
        clearTimeout(t)
      };
      document.getElementsByTagName("head")[0].appendChild(script)
      t = setTimeout(function () {
        location.href = "http://a.app.qq.com/o/simple.jsp?pkgname=com.Kingdee.Express&ckey=CK1413979100250&android_schema=" + encodeURIComponent(src)
      }, 1000)
    }
  },
  loadScript(src, callback) {
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    document.head.appendChild(script)
    script.onload = function() {
      util.isFunc(callback) && callback()
    }
  },
  getLastPage() {
    let history = util.getSession("$localHistory") || []
    return history.length > 1 ? history[history.length - 2] : null
  },
  singleClone(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  hidePhone(phone) {
    if(!phone) return ''
    return mobileReg.test(phone) || /^1\d{2}[*]{4}\d{4}$/.test(phone) ? phone.replace(/(\d{3}).{4}(\d{4})/, '$1****$2') : '***' + phone.substring(phone.length - 4, phone.length) // phone.substring(0, phone.length - 4) + '****'
  },
  ua: ua,
  isIOS: ua.indexOf("iphone") != -1 || ua.indexOf("ipad") != -1,
  isAndroid: ua.indexOf("android") != -1,
}

export default util