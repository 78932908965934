<template>
  <popup :show="show" :flex="true" @click="close">
    <div class="main" :style="{backgroundImage: 'url('+background+')'}" @click.stop>
      <img class="code" :src="code">
      <div class="footer">
        <img src="https://cdn.kuaidi100.com/images/m/dispatch/hand.png">
        <span>长按识别二维码</span><br><span>前往</span><span style="color:#ff7f02">『快递100』小程序</span>
      </div>
    </div>
  </popup>
</template>
<script>
import util from "lib/util"
export default {
  props: {
    uuid: String,
    force: Boolean, //关闭后每次重新打开都会强制展示,
    wechatCode: String,
    wechatPath: {
      type: String,
      default: "pages/index/index"
    },
    wechatParams: {
      type: String,
      default: "source=weixinKuaidi100"
    },
    baiduCode: {
      type: String,
      default: "https://www.kuaidi100.com/twoCode.do?h=300&w=300&code=baiduboxapp://swan/a437DMFSxRxbWjGhwDDYlpxqIjYI2goB/pages/index/index/?channel=badiduH5&_baiduboxapp=%7B%22from%22%3A%22%22%2C%22ext%22%3A%7B%7D%7D&callback=_bdbox_js_275&upgrade=0"
    }
  },
  computed: {
    code: function() {
      if(this.$store.state.globalData.isWechat) {
        return this.wechatCode || `https://wx.kuaidi100.com/wxbuss.do?method=getWXACodeUnLimit&pagePath=${this.wechatPath}&scene=${this.wechatParams}`
      } else {
        return this.baiduCode
      }
    },
    background: function() {
      return this.$store.state.globalData.isWechat ? "https://cdn.kuaidi100.com/images/m/dispatch/bg_wx.png" : "https://cdn.kuaidi100.com/images/m/dispatch/bg_bd.png"
    }
  },  
  data() {
   return {
      today: "",
      show: false
   }
  },
  methods: {
    close() {
      !this.force && util.setStorage(this.key, this.today)
      this.show = false
      this.$nextTick(() => {
        this.$destroy()
      })
    }
  },
  created() {
    let date = new Date()
    let store = this.$store.state.globalData
    this.today = ('0' + date.getDate()).slice(-2)
    this.key = `minipramGuideModal${this.uuid ? '_' + this.uuid : ''}`
    if(!store.isWechat && !store.isBaiduApp) return this.close()
    if(!this.force && (store.isMiniProgram || store.isQuickApp || store.coname || store.isApp || util.getStorage(this.key) === this.today)) return this.close()
    setTimeout(() => {//优先展示业务页面
      this.show = true
    }, 300) 
  }
  
}
</script>
<style scoped>
.main {
  width: 90%;
  height: 26.5rem;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.code {
  margin-top: 2.5rem;
  width: 14.75rem;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 44px;
  width: 100%;
  background-color: #dedede;
  color: #888;
  text-align: center;
  font-size: 13px;
  padding-top: 8px;
  border-radius: 6px;
}
.footer img{ 
  width: 0.875rem;
  margin-right: 5px;
  vertical-align: middle;
}
</style>